import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { IInterestsProps } from "./InterestsProps";
import Layout from "../../components/global/Layout/Layout";
import { getParagraph } from "../../utils/paragraphHelpers";
import ParagraphLayout from "../../components/common/ParagraphLayout/ParagraphLayout";
import SEO from "../../components/global/SEO/SEO";
import IParagraph from "../../@types/IParagraph";
import { Constants } from "../../@types/Constants";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
//import { addKoddiPageTag } from "../../utils/KoddiTagsGTM";

const Interests = (props: IInterestsProps) => {
  const node = { ...props.data.interestsInterests };
  const seoTitle = node.field_interest_snt_page_title;
  const seoDesc = node.field_interest_snt_meta_desc;
  const filterSonestaParagraphs = (paragraph: {
    relationships: { field_sites: { machine_name: string } };
  }) => {
    return paragraph?.relationships?.field_sites
      ? paragraph?.relationships.field_sites.machine_name === "sonesta"
        ? true
        : false
      : false;
  };
  // Filter Feature Based on SiteID
  const siteParagraphs = node?.relationships.field_interest_sections?.filter(
    filterSonestaParagraphs
  );
  const belowSearchSiteParagraphs =
    node?.relationships.paragraphsBelowSearch?.filter(filterSonestaParagraphs);
  const paragraphs = siteParagraphs ? siteParagraphs.map(getParagraph) : [];
  const belowSearchParagraphs = belowSearchSiteParagraphs
    ? belowSearchSiteParagraphs.map(getParagraph)
    : [];
  const pageBanner = paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });

  useEffect(() => {
    addPageTypeInGTMDataLayer("search");
  }, []);

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDesc} koddiTitle="Interests" />
      {pageBanner}
      <ParagraphLayout {...paragraphs} />
      {/* Search result block will come here*/}
      <ParagraphLayout {...belowSearchParagraphs} />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    interestsInterests(path: { alias: { eq: $slug } }) {
      field_interest_snt_meta_desc
      field_interest_snt_page_title
      name
      path {
        alias
      }
      relationships {
        field_interest_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphFeaturedProperties
          ...ParagraphFrequentlyAskedQuestions
        }
        paragraphsBelowSearch: field_sections_below_search {
          type: __typename
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphFrequentlyAskedQuestions
        }
      }
    }
  }
`;

export default Interests;
